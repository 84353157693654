
const initialState = {
    storms: [],
    wardsStorms: {},
    invitations: []
};

export default function fetchedData(state = initialState, action){
    switch (action.type) {
        case "SET_STORMS":
            return{...state, storms: action.payload};
        case "SET_WARDS_STORMS":
            return {...state, wardsStorms: action.payload};
        case "SET_STORM_DEFAULT":
            return initialState;
        case "SET_INVITATIONS":
            return {...state, invitations: action.payload};
        case "SET_REFRESH_FUNCTION":
            return {...state, refreshFunction: action.payload};
        default:
            return state;
    }
}