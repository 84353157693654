import React, {Component} from 'react';
import GoogleLogin from 'react-google-login';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {setTokenId, setUserProfile, setAccessToken, isAuthenticated} from '../redux/Actions/account';
import {withNamespaces} from "react-i18next";
import {sendAuthorization, saveAuthorization, loadAuthorization} from "../utils/authService";

class Login extends Component {

    componentDidMount() {
        // Loading authorization from previously saved info in local storage
        const loadedAuth = loadAuthorization();
        if (loadedAuth) {
            this.props.setTokenId(loadedAuth.idToken);
            this.props.setUserProfile(loadedAuth.profile);
            this.props.setAccessToken(loadedAuth.accessToken);
            this.props.isAuthenticated(true);
        }
    }

    responseGoogle = (response) => {
        let promise = sendAuthorization("/user/authByToken", response.getAuthResponse().id_token);

        promise.then((data) => {
            if (!data)
                return;
            this.props.setTokenId(response.getAuthResponse().id_token);
            this.props.setUserProfile(response.getBasicProfile());
            this.props.setAccessToken(response.accessToken);
            saveAuthorization(response);
            this.props.isAuthenticated(true);
        })

    };

    errorResponseGoogle = (e)=> {
        console.log(e);
    };

    render(){
        return (
            <div className="container App" style={{background: "#2A3F54"}}>
                <img style={{width: '280px'}} src="/img/heart_splash_heart.png" alt="logo"/>
                <div>
                <GoogleLogin
                    onSuccess={this.responseGoogle}
                    onFailure={this.errorResponseGoogle}
                    render={renderProps => (
                        <button className="sign-in" onClick={renderProps.onClick} width="250px">
                            {this.props.t('LoginButtonText')} Google
                        </button>
                    )}
                    buttonText="Ввойти через"
                    prompt="select_account"
                    clientId="774554471186-s24m6fn4et9f2cqr9n7d8s4sb97lhvtk.apps.googleusercontent.com"/>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setTokenId,
        setUserProfile,
        setAccessToken,
        isAuthenticated
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Login));