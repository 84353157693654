import React from "react";
import {Link} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import {clearAuthorization, sendAuthorization} from "../../../utils/authService";

const LogoutButton = (props) => {

    const logout = () => {
        sendAuthorization("/logout", '');
        clearAuthorization();
        props.setStormDefault();
        props.setInitDefault();
    };

    return (
        <Link
            to="/"
            className="logout"
            onClick={logout}
            style={{'--logout-text' : "'" + props.t('Exit') + "'"}}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg>
        </Link>
    );
};

export default withNamespaces()(LogoutButton)