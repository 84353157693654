import React from "react";
import {withNamespaces} from "react-i18next";

const TopTableRow = (props) => {
    return (
        <div className="flexRow flexTopRow">
            <div><span>{props.t('date')}</span></div>
            <div><span>{props.t('intensity')}</span></div>
            <div><span>{props.t('hLetter')}</span></div>
            <div><span>{props.t('eLetter')}</span></div>
            <div><span>{props.t('aLetter')}</span></div>
            <div><span>{props.t('rLetter')}</span></div>
            <div><span>{props.t('tLetter')}</span></div>
        </div>
    );
};

export default withNamespaces()(TopTableRow)