import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import {createStore} from "redux";
import AllReducers from "./redux/Reducers"
import i18n from './i18n';
import {I18nextProvider} from "react-i18next";
import "./scss/main.scss"

// export const store = createStore(
//     AllReducers,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

export const store = createStore(
    AllReducers
);

ReactDOM.render(
    <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
    </Provider>,
    document.getElementById('root'));

