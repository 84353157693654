import React from "react";
import i18n from "../../../i18n";

class LanguageSwitchButtons extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lng: i18n.language
        }
    }

    changeLng = lng => {
        i18n.changeLanguage(lng);
        this.setState({lng});
        localStorage.setItem('SELECTED_LANGUAGE', lng);
    };

    render() {
        let languages = ['ru', 'ua', 'en'];
        languages = languages.filter(lng => lng !== this.state.lng);
        languages.push(this.state.lng);
        const languagesList = languages.map((item, index) => {
            let className = "";
            if (item === this.state.lng)
                className = "active";

            return (
                <li
                    key={index}
                    onClick={() => this.changeLng(item)}
                    className={className}
                >
                    {item.toLocaleUpperCase()}
                </li>
            )
        });

        return (
            <div className="languageSwitch">
                <ul>
                    <span>
                        {languagesList}
                    </span>
                </ul>
            </div>
        );
    }
}

export default LanguageSwitchButtons