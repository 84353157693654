import React from "react";
import RoutesData from "../../data/Routes";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {withNamespaces} from "react-i18next";

class NavLinks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuItems: []
        }
    }

    componentDidMount() {
        this.getMenuItems()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pathName !== this.props.pathName || prevProps.t !== this.props.t)
            this.getMenuItems()
    }

    getMenuItems = () => {
        const menuItems = RoutesData.map((item, index) => {
            let className = "link";
            if ((index === 0 && this.props.pathName === "/")
                || this.props.pathName.match(item.link)
                || this.props.pathName.match(item.selectionPath))
                className = "link selected";

            return (
                <li className={className} key={index}>
                    <Link to={item.link}>
                        <img src={item.img} alt="icon"/>
                        <span>{this.props.t(item.translation)}</span>
                    </Link>
                </li>
            )
        });

        this.setState({menuItems})
    };

    render() {
        return (
            <div className="navBarContainer">
                <nav className="navBarLinksContainer">
                    {this.state.menuItems}
                </nav>
            </div>
        );
    }
}

NavLinks.propTypes = {
    pathName: PropTypes.string
};

export default withNamespaces()(NavLinks)