import React from "react";
import {getData} from "../utils/apiService";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import {setInvitations, setWardsStorms} from "../redux/Actions/fetchedData";

class Wards extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            wardsList: []
        }
    }

    componentDidMount() {
        this.getWards();
        this.getWardsList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetchedData.invitations !== this.props.fetchedData.invitations || prevProps.t !== this.props.t)
            this.getWardsList()
    }

    getWards = () => {
        getData('/user/myInvitations', {updating: (this.props.fetchedData.invitations.length !== 0)})
            .then(res => {
                this.props.setInvitations(res)
            })
    };

    setWardNameAndId = (name, id, fullName) => {
        let wards = this.props.fetchedData.wardsStorms;
        if (wards[name])
            return;
        wards[name] = {
            id,
            fullName,
            fetchedData: []
        };
        this.props.setWardsStorms(wards);
    };

    getWardInformation = (item) => {
        if (item.status !== "ACCEPTED" && item.status !== "PENDING")
            return null;

        if (this.props.account.userEmail !== item.emailOfInvited) {
            if (item.roleOfInvited !== "WARD")
                return null;
            return {
                name: item.nameOfInvited,
                photo: item.photoOfInvited,
                email: item.emailOfInvited,
                userId: item.userIdOfInvited
            }
        }

        if (item.roleOfInvited !== "COUNSELOR")
            return null;
        return {
            name: item.owner.name,
            photo: item.owner.photo,
            email: item.owner.email,
            userId: item.owner.userId
        }

    };

    getWardsList = () => {
        const wardsList = this.props.fetchedData.invitations.map(item => {
            const info = this.getWardInformation(item);
            if (!info)
                return "";

            if (item.status === "PENDING")
                return (
                    <div key={item.id} className="invitationItem">
                        <img src={info.photo} alt="Ward" />
                        <span>{info.name}</span>
                        <span className="notAccepted">{this.props.t('NotAccepted')}</span>
                    </div>
                );

            let nameFromEmail;
            try {
                nameFromEmail = info.email.match(new RegExp('[^]*@'))[0];
                nameFromEmail = nameFromEmail.slice(0, nameFromEmail.length - 1);
            } catch (e) {
                nameFromEmail = info.userId;
            }
            return (
                <Link
                    key={item.id}
                    to={"/ward/storms/" + nameFromEmail}
                    onClick={() => {this.setWardNameAndId(nameFromEmail, info.userId, info.name)}}
                    className="invitationItem">
                    <img src={info.photo} alt="Ward" />
                    <span>{info.name}</span>
                </Link>
            )

        });

        this.setState({wardsList})
    };

    render() {
        return (
            <div>
                <div className="invitationsList">
                    {this.state.wardsList}
                </div>
                <div
                    id="refresh"
                    onClick={this.getWards}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        fetchedData: state.fetchedData
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setInvitations,
        setWardsStorms
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Wards));