const initialState = {
    tokenId:'',
    userImageURL:'',
    userFullName:'',
    userGivenName:'',
    userFamilyName:'',
    userEmail:'',
    isAuthenticated: false,
    accessToken: ''
};

export default function account(state = initialState, action) {
    switch (action.type) {
        case "SET_TOKEN_ID":
            return{...state, tokenId: action.payload};
        case "SET_USER_PROFILE":
            return{...state,
                userImageURL: action.payload.Paa,
                userFullName: action.payload.ig,
                userGivenName: action.payload.ofa,
                userFamilyName: action.payload.wea,
                userEmail: action.payload.U3
            };
        case "SET_AUTHENTICATED":
            return{...state, isAuthenticated:action.payload};
        case "SET_ACCESS_TOKEN":
            return{...state, accessToken: action.payload};
        case "SET_INIT_DEFAULT":
            return initialState;
        default:
            return state;
    }

}