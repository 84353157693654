export const showLoader = () => {
    document.getElementById('loader').style.visibility = 'visible';
    document.getElementById('loader').style.opacity = '1';
};

export const showUpdater = () => {
    document.getElementById('heart-updater').style.visibility = 'visible';
    document.getElementById('heart-updater').style.opacity = '1';
};

export const hideLoader = () => {
    document.getElementById('loader').style.opacity = '0';
    document.getElementById('loader').style.visibility = 'hidden';
    document.getElementById('heart-updater').style.opacity = '0';
    document.getElementById('heart-updater').style.visibility = 'hidden';
};