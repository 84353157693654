import React from "react";

const SandwichButton = () => {

    const nav = document.getElementById("navBar");

    const handleSandwichClick = () => {
        if (!nav)
            return;

        if (nav.classList.contains("closed")) {
            nav.classList.remove("closed");
        } else {
            nav.classList.add("closed");
        }
    };

    return (
        <div className="sandwich">
            <div onClick={handleSandwichClick}>
                <div /><div /><div />
            </div>
        </div>
    );
};

export default SandwichButton