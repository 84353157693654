import {reactI18nextModule} from "react-i18next";
import translationRU from "./locales/ru/translation";
import translationEN from "./locales/en/translation";
import translationUA from "./locales/ua/translation";
import i18n from "i18next";

const resources = {
    "ru": {
        translation: translationRU
    },
    "en": {
        translation: translationEN
    },
    "ua": {
        translation: translationUA
    }
};

const getPageLanguage = () => {
    const lng = localStorage.getItem("SELECTED_LANGUAGE");
    return (lng) ? lng : 'ru';
};

i18n.use(reactI18nextModule)
    .init({
        resources,
        lng: getPageLanguage(),
        keySeparator: false,
        interpolation: {escapeValue: false}
    });

export default i18n;