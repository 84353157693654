import React from "react";
import SandwichButton from "./SandwichButton";
import RefreshButton from "./RefreshButton";
import LanguageSwitchButtons from "./LanguageSwitchButtons";
import LogoutButton from "./LogoutButton";

const TopNav = (props) => {
    return (
        <div className="topNav">
            <div className="topNavContainer">
                <div className="sandwichRefreshContainer">
                    <SandwichButton />
                    <RefreshButton />
                </div>
                <div className="languageSwitchContainer">
                    <LanguageSwitchButtons />
                    <LogoutButton
                        {...props}
                    />
                </div>
            </div>
        </div>
    );
};

export default TopNav