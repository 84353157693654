import React from "react";
import TopTableRow from "./TopTableRow";
import PropTypes from 'prop-types';
import ColorMenu from "../elements/ColorMenu";

class StormsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: []
        }
    }

    componentDidMount() {
        this.getTableRows()
    }

    getHighlight = () => {
        const selection = window.getSelection();
        let object = {
            parent: null,
            text: '',
            rect: null
        };

        if (selection.rangeCount > 0) {
            object = {
                text: selection.toString().trim(),
                parent: selection.anchorNode.parentNode,
                rect: selection.getRangeAt(0).getBoundingClientRect()
            };
        }
        return object;
    };

    showColorMenu = () => {
        const coloring = document.querySelector('.coloring');
        const highlight = this.getHighlight();

        if (highlight.text === '') {
            coloring.setAttribute('class', 'coloring');
            coloring.style.left = 0;
            coloring.style.top = 0;
            return;
        }

        const width = (highlight.rect.width / 2) - 42;

        coloring.setAttribute('class', 'coloring coloring_shown');
        coloring.style.left = (highlight.rect.left + width) + 'px';
        coloring.style.top = (highlight.rect.top - 55) + 'px';
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.stormsList !== this.props.stormsList) {
            this.getTableRows();
        }
    }

    getTableRows = () => {
        this.props.stormsList.sort((a, b) => a.creationDate > b.creationDate ? -1 : 1);

        const rows = this.props.stormsList.map(item => {
            const slicedCreationDate = item.creationDate.slice(0, 10);
            const creationDate = new Date(slicedCreationDate);
            return (
                <div key={item.stormId} className="flexRow">
                    <div><span>{creationDate.getDate() + '/' + creationDate.getMonth() + "/" + creationDate.getFullYear().toString().slice(2)}</span></div>
                    <div><span>{item.intensity}</span></div>
                    <div>{item.heartQuestions[0].description}</div>
                    <div>{item.heartQuestions[1].description}</div>
                    <div>{item.heartQuestions[2].description}</div>
                    <div>{item.heartQuestions[3].description}</div>
                    <div>{item.heartQuestions[4].description}</div>
                </div>
            )
        });

        this.setState({rows});
    };

    render() {
        return (
            <div className="flexTable" onMouseUp={() => setTimeout(this.showColorMenu, 100)}>
                <TopTableRow />
                {this.state.rows}
                <ColorMenu/>
            </div>
        );
    }
}

StormsTable.propTypes = {
    stormsList: PropTypes.array
};

export default StormsTable
