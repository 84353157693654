export const setStorms = (storms) => {
    return {
        type: "SET_STORMS",
        payload: storms
    }
};

export const setWardsStorms = (storms) => {
    return {
        type: "SET_WARDS_STORMS",
        payload: storms
    }
};

export const setStormDefault = () => {
    return {
        type: "SET_STORM_DEFAULT"
    }
};

export const setInvitations = (invitations) => {
    return {
        type: "SET_INVITATIONS",
        payload: invitations
    }
};
