import React from "react";
import {bindActionCreators} from "redux";
import {setStorms} from "../redux/Actions/fetchedData";
import {connect} from "react-redux";
import {getData} from "../utils/apiService";
import StormsTable from "./StormsTable"

class MyStorms extends React.Component {

    componentDidMount() {
        this.getStorms();
    }

    getStorms = () => {
        getData("/storm", {updating: (this.props.fetchedData.storms.length !== 0)})
            .then(res => {
                this.props.setStorms(res);
            });
    };

    render() {
        const {storms} = this.props.fetchedData;

        return (
            <div>
                <StormsTable
                    stormsList = {storms}
                />
                <div
                    id="refresh"
                    onClick={this.getStorms}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {fetchedData: state.fetchedData}
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setStorms
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MyStorms);
