export const setTokenId = (tokenId) => {
    return {
        type: "SET_TOKEN_ID",
        payload: tokenId
    }
};

export const setUserProfile = (profile) =>{
    return {
        type: "SET_USER_PROFILE",
        payload: profile
    }
};

export const isAuthenticated = (auth)=>{
    return {
        type: "SET_AUTHENTICATED",
        payload: auth
    }
};

export const setAccessToken = (token) =>{
    return {
        type:"SET_ACCESS_TOKEN",
        payload: token
    }
};

export const setInitDefault = () => {
    return {
        type: "SET_INIT_DEFAULT"
    }
};
