import React from "react";
import {getData} from "../utils/apiService";
import {bindActionCreators} from "redux";
import {setInvitations} from "../redux/Actions/fetchedData";
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";

class Counselors extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            counselorsList: []
        }
    }

    componentDidMount() {
        this.getCounselors();
        this.getCounselorsList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetchedData.invitations !== this.props.fetchedData.invitations || prevProps.t !== this.props.t)
            this.getCounselorsList()
    }

    getCounselors = () => {
        getData('/user/myInvitations', {updating: (this.props.fetchedData.invitations.length !== 0)})
            .then(res => {
                this.props.setInvitations(res)
            })
    };

    getCounselorInformation = (item) => {
        if (item.status !== "ACCEPTED" && item.status !== "PENDING")
            return null;

        if (this.props.account.userEmail !== item.emailOfInvited) {
            if (item.roleOfInvited !== "COUNSELOR")
                return null;
            return {
                name: item.nameOfInvited,
                photo: item.photoOfInvited
            }
        }

        if (item.roleOfInvited !== "WARD")
            return null;
        return {
            name: item.owner.name,
            photo: item.owner.photo
        }

    };

    getCounselorsList = () => {
        const counselorsList = this.props.fetchedData.invitations.map(item => {
            const info = this.getCounselorInformation(item);
            if (!info)
                return "";

            if (item.status === "ACCEPTED")
                return (
                    <div key={item.id} className="invitationItem">
                        <img src={info.photo} alt="Counselor" />
                        <span>{info.name}</span>
                    </div>
                );
            return (
                <div key={item.id} className="invitationItem">
                    <img src={info.photo} alt="Counselor" />
                    <span>{info.name}</span>
                    <span className="notAccepted"><div>{this.props.t('NotAccepted')}</div></span>
                </div>
            )
        });

        this.setState({counselorsList});
    };

    render() {
        return (
            <div>
                <div className="invitationsList">
                    {this.state.counselorsList}
                </div>
                <div
                    id="refresh"
                    onClick={this.getCounselors}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        fetchedData: state.fetchedData
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setInvitations
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Counselors));