import React, {Component} from 'react';
import Login from './components/Login';
import {connect} from "react-redux";
// import {bindActionCreators} from 'redux';
// import * as initAction from "./Actions/account";
import LandingPage from './components/LandingPage';

class App extends Component {

  render() {
    const elementToShow =  this.props.account.isAuthenticated?<LandingPage />:<Login/>
    return (
      <div>
          {elementToShow}
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        account: state.account,
    }
};
//
// const mapDispatchToProps = dispatch =>{
//     return{
//       initAction:bindActionCreators(initAction, dispatch)
//     }
// }

// export const mapDispatchToProps = dispatch => bindActionCreators(
//     {
//         initAction,
//     },
//     dispatch,
// );


export default connect(mapStateToProps)(App);
