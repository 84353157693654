import React from "react";
import {connect} from "react-redux";
import StormsTable from "./StormsTable"
import {Redirect} from "react-router";
import {bindActionCreators} from "redux";
import {getData} from "../utils/apiService";
import {setWardsStorms} from "../redux/Actions/fetchedData";

class WardStorms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            printTable: false
        }
    }

    componentDidMount() {
        const name = this.props.match.params.name;
        let wards = this.props.fetchedData.wardsStorms;

        if (wards[name])
            this.getStorms(wards, name);
        else
            this.tryToFindWardByLink(wards, name);
    }

    getStorms = (wards, name) => {
        this.setState({printTable: true});
        getData('/storm/user/' + wards[name].id, {updating: (wards[name].fetchedData.length !== 0)})
            .then(res => {
                wards[name].fetchedData = res;
                this.props.setWardsStorms(wards);
            });
    };

    tryToFindWardByLink = (wards, name) => {
        getData('/user/sharedWithMe')
            .then(res => {
                let found = false;
                res.forEach(item => {
                    if (!found && item.email.match(name + '@')) {
                        wards[name] = {
                            id: item.userId,
                            fullName: item.name,
                            fetchedData: []
                        };
                        found = true;
                    }
                });
                if (found) {
                    this.props.setWardsStorms(wards);
                    this.getStorms(wards, name);
                }
                else
                    this.setState({redirect: true});
            })
    };

    refreshStorms = () => {
        const name = this.props.match.params.name;
        let wards = this.props.fetchedData.wardsStorms;

        getData('/storm/user/' + wards[name].id, {updating: (wards[name].fetchedData.length !== 0)})
            .then(res => {
                wards[name].fetchedData = res;
                this.props.setWardsStorms(wards);
            });
    };

    render() {
        if (this.state.redirect)
            return <Redirect to='/wards' />;

        if (this.state.printTable)
            return (
                <div>
                    <div className="stormUser">{this.props.fetchedData.wardsStorms[this.props.match.params.name].fullName}</div>
                    <StormsTable
                        stormsList={this.props.fetchedData.wardsStorms[this.props.match.params.name].fetchedData}
                    />
                    <div
                        id="refresh"
                        onClick={this.refreshStorms}
                    />
                </div>
            );

        return <div />
    }
}

const mapStateToProps = state => {
    return {fetchedData: state.fetchedData}
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setWardsStorms
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(WardStorms);
