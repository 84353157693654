import {hideLoader, showLoader} from "./loader";
import axios from 'axios';
import "./serviceDefaults"

export function saveAuthorization(response) {
    localStorage.setItem("AUTHORIZATION_RESPONSE", JSON.stringify({
        idToken: response.getAuthResponse().id_token,
        profile: response.getBasicProfile(),
        accessToken: response.accessToken,
        creatingDate: Date.now()
    }));
}

export function loadAuthorization() {
    const responseJson = localStorage.getItem("AUTHORIZATION_RESPONSE");

    if (responseJson) {
        const response = JSON.parse(responseJson);
        if (Date.now() - response.creatingDate < 1000 * 60 * 60) {
            return response
        }
    }
    localStorage.removeItem("AUTHORIZATION_RESPONSE");
    return null
}

export function clearAuthorization() {
    localStorage.removeItem("AUTHORIZATION_RESPONSE");
}

export function sendAuthorization(endpoint, token) {
    showLoader();

    return new Promise((resolve) => {
        axios.post(endpoint,
            "token=" + token
            , {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .then(function (response) {
                resolve(response.data);
                // console.log(response);
                hideLoader();
            })
            .catch(function (error) {
                console.log(error);
                hideLoader();
            });
    });

}


