import MyStorms from "../components/MyStorms";
import Counselors from "../components/Counselors";
import Wards from "../components/Wards";

const RoutesData = [
    {
        id: 0,
        translation: "Storms",
        link: "/my-storms",
        selectionPath: "/my-storms",
        component: MyStorms,
        img: "/img/ic_storms.png"

    },
    {
        id: 1,
        translation: "Counselors",
        link: "/counselors",
        selectionPath: "/counselors",
        component: Counselors,
        img: "/img/ic_teachers.png"
    },
    {
        id: 2,
        translation: "Wards",
        link: "/wards",
        selectionPath: "/ward/storms",
        component: Wards,
        img: "/img/ic_students.png"
    },
];

export default RoutesData