import React from "react"
import NavLinks from "./NavLinks";
import TopNav from "./TopNav";

const NavBar = (props) => {
    return (
        <div id="navBar" className="navBar">
            <div className="navBarInside">
                <div className="accountContainer">
                    <img src={props.account.userImageURL} alt="Account"/>
                    <div>
                        <h2>{props.account.userFullName}</h2>
                    </div>
                </div>
                <NavLinks
                    pathName={props.location.pathname}
                />
                <TopNav
                    setStormDefault={props.setStormDefault}
                    setInitDefault={props.setInitDefault}
                />
            </div>
            <div className="navBarSizer" />
        </div>
    );
};

export default NavBar
