import React from "react"
import NavBar from "./NavBar";
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import RoutesData from "./../data/Routes"
import {bindActionCreators} from "redux";
import {setInitDefault} from "../redux/Actions/account";
import {setStormDefault} from "../redux/Actions/fetchedData";
import {connect} from "react-redux";
import WardStorms from "./WardStorms";

class LandingPage extends React.Component {

    render() {
        // Getting all routes which included in Nav bar
        const routes = RoutesData.map(item => {
            return (
                <Route
                    exact
                    path={item.link}
                    component={item.component}
                    key={item.id}
                />
            );
        });

        return (
            <div>
                <div className="landingPage">
                    <Router>
                        {/* Nav bar route to get pathname for indicating selected link */}
                        <Route
                            path="/"
                            render={(props) =>
                                <NavBar
                                    {...props}
                                    account={this.props.account}
                                    setStormDefault={this.props.setStormDefault}
                                    setInitDefault={this.props.setInitDefault}
                                />}
                        />

                        <div className="content">
                            <div className="contentInside">
                                <Switch>
                                    {routes}

                                    {/* Ward storms page, which link is not included into Nav bar, and RoutesData array */}
                                    <Route exact path="/ward/storms/:name" component={WardStorms}/>

                                    {/* Redirecting to the first page, when page is not found */}
                                    <Redirect to={RoutesData[0].link} />

                                </Switch>
                            </div>
                            <div className="copyright">
                                <a href={"http://neatapps.co"} rel="noopener noreferrer" target="_blank">© Neatapps 2019</a>
                            </div>
                        </div>
                    </Router>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        fetchedData: state.fetchedData
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setInitDefault,
        setStormDefault
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
