import axios from "axios"
import {store} from "../index";
import {sendAuthorization} from "./authService";
import {setInitDefault} from "../redux/Actions/account";
import {showLoader, hideLoader, showUpdater} from "./loader";
import "./serviceDefaults"

export function getData(endPoint, config = {updating: false}) {
    if (config.updating)
        showUpdater();
    else
        showLoader();
    let getDataAttempts = 0;

    return new Promise((resolve, reject) => {
        axios.get(endPoint, {
            withCredentials: true,
            headers: {
                "Accept":"application/json"
            }
        })
            .then(function (response) {
                resolve(response.data);
                hideLoader();
            })
            .catch(function (error) {
                if (getDataAttempts === 0) {
                    getDataAttempts++;
                    sendAuthorization("/user/authByToken", JSON.parse(localStorage.getItem("AUTHORIZATION_RESPONSE")).idToken)
                        .then(() => resolve(getData(endPoint)));
                } else {
                    store.dispatch(setInitDefault());
                    reject(error);
                }
                hideLoader();
            });
    });
}